<template>
  <div ref="qqmap" style="height: 200px; flex: 0 0 100%" />
</template>

<script>
export default {
  name: 'MapChoose',
  data() {
    return {
      markersLayer: null,
      geometries: null,
      dataForm: {
        address: '',
        longitude: null,
        latitude: null,
      },
    };
  },
  methods: {
    // 定位地址
    async getAddress(data) {
      if (this.markersLayer) {
        this.markersLayer.setGeometries([]);
      }
      const res = await this.$jsonp(`https://apis.map.qq.com/ws/geocoder/v1/`, {
        address: data,
        key: 'NAXBZ-I3DW2-JYWUA-C4CTO-SD3HV-XIF3X',
        output: 'jsonp',
      });
      if (res.status === 0) {
        this.dataForm.longitude = res.result.location.lng;
        this.dataForm.latitude = res.result.location.lat;
        let latLon = new window.TMap.LatLng(
          res.result.location.lat,
          res.result.location.lng,
        );
        if (this.geocoder) {
          this.geocoder.setCenter(latLon);
        } else {
          this.geocoder = new window.TMap.Map(this.$refs.qqmap, {
            zoom: 14,
            center: latLon,
          });
        }
        this.markersLayer = new window.TMap.MultiMarker({
          map: this.geocoder,
          geometries: [{ position: latLon }],
        });

        this.geocoder.on('click', (e) => {
          this.geometries = [{ position: e.latLng }];
          this.markersLayer.setGeometries(this.geometries);
          this.getCode(e.latLng);
          this.geocoder.setCenter(e.latLng);
        });
      } else {
        this.dataForm.longitude = null;
        this.dataForm.latitude = null;
        this.$message.error('无法搜索到相关的地址信息');
      }
      return this.dataForm;
    },
    getCode(data) {
      this.$jsonp(`https://apis.map.qq.com/ws/geocoder/v1/`, {
        location: data.getLat() + ',' + data.getLng(),
        key: 'NAXBZ-I3DW2-JYWUA-C4CTO-SD3HV-XIF3X',
        output: 'jsonp',
      }).then((res) => {
        if (res.status === 0) {
          let area;
          if (res.result.address_reference.landmark_l2.title) {
            area = res.result.address_reference.landmark_l2.title;
          } else if (res.result.address_reference.street_number) {
            area = res.result.address_reference.street_number.street;
          } else {
            area = res.result.address_reference.town.title;
          }
          this.dataForm.address = res.result.address + area;
          this.$emit('newAddress', this.dataForm.address);
        }
      });
    },
  },
};
</script>

<style>
.cropper-content {
  .cropper {
    width: auto;
    height: 350px;
  }
}
</style>
