<template>
  <el-dialog
    title="证件上传"
    :close-on-click-modal="false"
    :visible.sync="uploadVisible"
    class="missionPart"
    append-to-body
  >
    <el-form
      ref="dataForm"
      :model="dataForm"
      :rules="dataRule"
      label-width="120px"
      @keyup.enter.native="dataFormSubmit()"
    >
      <div class="tablePart">
        <el-form-item label="证件图片">
          <el-upload
            drag
            :action="url"
            :before-upload="beforeUploadHandle"
            :on-success="successHandle"
            :on-remove="handleRemove"
            multiple
            :file-list="dataForm.fileList"
            style="text-align: center"
          >
            <i class="el-icon-upload" />
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
            <div slot="tip" class="el-upload__tip">
              只支持jpg、png、gif格式的图片！
            </div>
          </el-upload>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="uploadVisible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      uploadVisible: false,
      file: '',
      url: '',
      fileName: '',
      dataForm: {
        fileList: [], // 图片列表汇总
      },
      dataRule: {
        imageUrl: [
          {
            required: true,
            message: '轮播图片不能为空',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {},
  components: {},
  methods: {
    init() {
      this.uploadVisible = true;
      this.dataForm.fileList = [];
      this.url =
        this.$http.BASE_URL +
        `/sys/oss/upload?token=${this.$cookie.get('token')}`;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.uploadVisible = false;
      this.$emit('success', this.dataForm.fileList);
    },
    beforeUploadHandle(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (
        file.type !== 'image/jpg' &&
        file.type !== 'image/jpeg' &&
        file.type !== 'image/png' &&
        file.type !== 'image/gif'
      ) {
        this.$message.error('只支持jpg、png、gif格式的图片！');
        return false;
      }
      if (!isLt10M) {
        this.$message.error('上传文件大小不能超过10MB哦!');
        return false;
      }
      this.num++;
    },
    handleRemove(file, fileList) {
      let deleteIndex = this.dataForm.fileList.findIndex(
        (item) => item.uid === file.uid,
      );
      if (deleteIndex !== -1) {
        this.dataForm.fileList.splice(deleteIndex, 1);
      }
    },
    // 上传成功
    successHandle(response, file, fileList) {
      this.successNum++;
      if (response && response.code === 0) {
        // this.dataForm.fileList = fileList;
        this.dataForm.fileList.push({
          url: response.title,
          name: file.name.split('.')[0],
          uid: file.uid,
          expiryDate: '',
        });
        this.$confirm('操作成功, 是否继续操作?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).catch(() => {
          this.uploadVisible = false;
        });
      } else {
        this.$message.error(response.msg);
      }
    },
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  border-radius: 0 !important;
  display: block;
}
</style>
